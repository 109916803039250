import React from 'react';
import { Container, Typography, Button, Grid, Paper } from '@mui/material';

function App() {
  return (
    <Container
      maxWidth="md"
      style={{
        marginTop: '20px',
        backgroundColor: 'black',
        minHeight: '100vh',
        color: 'white',
      }}
    >
      <Paper elevation={3} style={{ padding: '20px', backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
        <Typography variant="h3" component="h1" gutterBottom>
          Hoş Geldiniz
        </Typography>
        <Typography variant="h6" gutterBottom>
          Bu, Material-UI ile oluşturulmuş basit bir ana sayfadır.
        </Typography>
        
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Button variant="contained" color="primary" fullWidth>
              İlk Buton
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button variant="outlined" color="secondary" fullWidth>
              İkinci Buton
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

export default App;
